import { render, staticRenderFns } from "./enterDetail.vue?vue&type=template&id=c80d6ab8&scoped=true&"
import script from "./enterDetail.vue?vue&type=script&lang=js&"
export * from "./enterDetail.vue?vue&type=script&lang=js&"
import style0 from "./enterDetail.vue?vue&type=style&index=0&id=c80d6ab8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c80d6ab8",
  null
  
)

export default component.exports