<!-- 企业专区 -->
<template>
	<div class="compony">
		<div class="area">
			<div class="ae-img">
				<div class="ae-img2">
					<img :src="area.thumb" alt="">
				</div>
				<div class="ae-img-list" v-show="false">
					<div class="arrow"><i class="iconfont">&#xe635;</i></div>
					<div class="ae-img-li">
						<img src="https://img.js.design/assets/img/62a149d147a562fa09d42e58.png" alt="" v-for="(item,index) in 4" :key="index">
					</div>
					<div class="arrow"><i class="iconfont">&#xe642;</i></div>
				</div>
			</div>
			<div class="ae-ctx">
				<h1>{{area.title}}</h1>
				<div>
					<p>所属板块</p>
					<p>{{area.plate_name}}</p>
				</div>
				<div>
					<p>所属行业</p>
					<p>{{area.industry_name}}</p>
				</div>
				<div>
					<p>挂牌日期</p>
					<p>{{time(area.inputtime)}}</p>
				</div>
				<div>
					<p>主营业务</p>
					<p>{{area.business}}</p>
				</div>
				<div>
					<p>企业代码</p>
					<p>{{area.code}}</p>
				</div>
			</div>
		</div>
		
		<!-- 企业概括 -->
		<div class="gaik" v-html="area.content"></div>
	</div>
</template>

<script>
	import moment from "moment"
	import util from "@/assets/script/util.js"
	export default {
		data() {
			return {
				taps:['企业概括'],
				area:{}
			}
		},
		created() {
			this.getDetail()
		},
		computed:{
			time() {
				return (val)=>{
					return moment(val*1000).format("YYYY-MM-DD")
				}
			}
		},
		methods:{
			// 查看详情
			getDetail() {
				let type = this.$route.query.type
				let id = this.$route.query.id
				this.$axios.get("Specialization/getEnterpriseDetail",{
					params:{
						id
					}
				}).then(res=>{
					if(res.code) {
						res.data.content = util.setContent(res.data.content)
						this.area = res.data
					}
				})
			},
			// 点击面包屑
			clkItem(index) {
				let type = this.$route.query.type
				if(index==0) {
					this.$router.push({name:"scIndex",query:{type}})
				}else if(index==1) {
					this.$router.push({name:"area",query:{type}})
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.sc-cotain {
		background-color: transparent !important;
	}
	.area {
		display: flex;
		max-width: 1440px;
		margin: 0 auto;
		background-color: #fff;
		padding: 30px;
		box-sizing: border-box;
		.ae-img {
			width: 600px;
			.ae-img2 {
				img {
					width: 100%;
				}
			}
			.ae-img-list {
				display: flex;
				margin-top: 20px;
				.arrow {
					width: 40px;
					height: 90px;
					line-height: 90px;
					text-align: center;
					flex-shrink: 0;
					background-color: #ebecef;
				}
				.ae-img-li {
					flex-grow: 1;
					display: flex;
					overflow: hidden;
					img {
						width: 25%;
						height: 90px;
						object-fit: cover;
						flex-shrink: 0;
						box-sizing: border-box;
					}
				}
			}
		}
		.ae-ctx {
			margin-left: 40px;
			h1 {
				line-height: 47px;
				color: rgba(51, 51, 51, 1);
				font-size: 32px;
				font-weight: 400;
				margin-bottom: 40px;
			}
			&>div {
				display: flex;
				margin-bottom: 12px;
				
				p {
					line-height: 24px;
					&:first-child {
						
						color: rgba(102, 102, 102, 1);
						font-size: 16px;
						font-weight: 400;
						margin-right: 16px;
					}
				}
			}
		}
	}
	// 企业概括
	.gaik {
		max-width: 1440px;
		margin: 40px auto 0 auto;
		background-color: #fff;
		padding: 30px;
		box-sizing: border-box;
		&>div {
			border-top: 0.5px solid rgba(235, 236, 239, 1);
			display: grid;
			grid-template-columns: repeat(4,1fr);
			padding: 24px 0;
			&:last-child {
				border-bottom: 0.5px solid rgba(235, 236, 239, 1);
			}
		}
	}
	.gk-tal {
		line-height: 28px;
		color: rgba(51, 51, 51, 1);
		font-size: 16px;
		line-height: 28px;
		font-weight: 400;
	}
	
	// 公司介绍
	.gsjs {
		max-width: 1440px;
		margin: 0 auto;
		background-color: #fff;
		padding: 30px;
	}
	
</style>